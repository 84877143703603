<!--<template>-->
<!--  <div class="login">-->
<!--    <b-container>-->
<!--        <b-alert -->
<!--          :show="dismissCountDown"-->
<!--          :variant="variant"-->
<!--          dismissible-->
<!--          @dismissed="dismissCountDown==0" -->
<!--          @dismiss-count-down="countDownChanged">-->
<!--          <p>{{ tempMessage }}</p>-->
<!--          <b-progress-->
<!--              variant="warning"-->
<!--              :max="dismissSeconds"-->
<!--              :value="dismissCountDown"-->
<!--              height="4px"-->
<!--          ></b-progress>-->
<!--        </b-alert>-->
<!--        <div class="mt-3">-->
<!--            <b-row>-->
<!--                <h4>Identify Container</h4>-->
<!--            </b-row>-->
<!--            <b-row>-->
<!--                <b-input-group class="mb-2">-->
<!--                    <b-input-->
<!--                        v-model="containerId"-->
<!--                        placeholder="Scan or enter container."-->
<!--                        v-on:keyup.enter="confirm()"/>-->
<!--                </b-input-group>-->
<!--            </b-row>-->
<!--        </div>-->
<!--        <div class="mt-3">-->
<!--            <b-row>-->
<!--                <b-button-group>-->
<!--                    <b-button v-on:click="confirm()">Confirm</b-button>-->
<!--                    <b-button v-on:click="induct()">Induct Cart</b-button>-->
<!--                </b-button-group>-->
<!--            </b-row>-->
<!--        </div>-->
<!--    </b-container>-->
<!--  </div>-->
<!--</template>-->
<template>
  <div>
    <v-alert
        v-show="isAlertVisible"
        :type="variant"
        dismissible>
      <p>{{ tempMessage }}</p>
    </v-alert>
    <div>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        Scan or enter cart
      </v-toolbar>
      <v-text-field
          v-model="containerId"
          placeholder="Container Barcode"
          v-on:keyup.enter="addContainer()"
          autofocus
      />
      <v-btn-toggle>
        <v-btn
            v-on:click="addContainer()"
            :dark="!isConfirmDisabled()"
            :disabled="isConfirmDisabled()"
        >
          Add Container
        </v-btn>
        <v-btn
            v-on:click="induct()"
            :dark="!isConfirmDisabled()"
            :disabled="isConfirmDisabled()"
        >
          Induct
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>
<script>
import * as fulfillment from "../../../js/fulfillment";
export default {
  name: 'AddPickContainerToCart',
  props: {
    msg: String
  },
  data() {
    return {
      zoneId: 'PICK01',
      userId: 'user1',
      currentState: '',
      cartId: '',
      containerId: '',
      dismissCountDown: 0,
      dismissSeconds: 5,
      variant: "success",
      tempMessage: "",
      isAlertVisible: false,
    }
  },
  methods: {
    countDownChanged: function (dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    isConfirmDisabled: function () {
      return this.containerId === '';
    },
    addContainer: function () {
      if (this.containerId === '') {
        this.tempMessage = 'Please identify a container.';
        this.variant = "warning";
        this.dismissCountDown = 5;
        return;
      }

      let addContainerToClusterRequest = {};
      addContainerToClusterRequest.containerId = this.containerId;
      addContainerToClusterRequest.clusterId = this.cartId;
      const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/api/picking/Cluster/AddContainer';
      console.log('url: ', appsUrl);
      console.log('Request: ', addContainerToClusterRequest);
      this.$http.post(appsUrl, addContainerToClusterRequest)
          .then((response) => {
            location.href = '#/picking/cart/' + this.containerId + '/addContainer';
          })
          .catch(function (error) {
            console.error('An error occurred while adding a pick container to the cart: ', error);
          });
    },
    induct: async function() {
      console.log('Inducting');
      if (this.containerId === '') {
        this.tempMessage = 'Please identify a container.';
        console.log('No container :(');
        return;
      }

      const worklistResponse = await fulfillment.getWorklistForCluster(this.userId, this.zoneId, this.cartId)
      console.log('Induct! ', worklistResponse);
      location.href = '#/picking/pick';
    },
  },
  mounted() {
    this.cartId = this.$route.params.cartId;
  }
}
</script>