import axios from "axios";
import * as configurations from './configurations.js';

export async function getWorklistForCluster(userId, zoneId, clusterId) {
    console.log('Starting getWorkListForCluster');
    const appsUrl = 'http://' + configurations.getHostUrl() + ':' + configurations.getPort() + '/api/picking/Work/WorkListForCluster';
    const request = {
        userId: userId,
        zoneId: zoneId,
        clusterId: clusterId
    }

    console.log('URL: ', appsUrl);
    const response = await axios.post(appsUrl, request);
    console.log('Response: ', response);
    console.log('Response data: ', response.data);
    return response.data;
}

export function getWorklistForContainer(userId, containerId) {

}

export function setClusterId(clusterId) {
    localStorage.setItem('clusterId', clusterId);
}

export function getClusterId() {
    return localStorage.getItem('clusterId');
}