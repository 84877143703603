<template>
  <div>
    <v-alert
        v-show="isAlertVisible"
        :type="variant"
        dismissible>
      <p>{{ tempMessage }}</p>
    </v-alert>
    <div>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        Scan or enter cart to build
      </v-toolbar>
      <v-text-field
          v-model="cartId"
          placeholder="Cart Barcode"
          v-on:keyup.enter="confirm()"
          autofocus
      />
      <v-btn-toggle>
        <v-btn
            v-on:click="confirm()"
            :dark="!isConfirmDisabled()"
            :disabled="isConfirmDisabled()"
        >
          Confirm
        </v-btn>
        <v-btn
          v-on:click="cancel()">
          Cancel
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PickingLogin',
  props: {
    msg: String
  },
  data() {
    return {
      zoneId: '',
      currentState: '',
      cartId: '',
      dismissCountDown: 0,
      dismissSeconds: 5,
      variant: "success",
      tempMessage: "",
    }
  },
  methods: {
    countDownChanged: function (dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    isConfirmDisabled: function () {
      return this.cartId === '';
    },
    isAlertVisible: function() {
      return false;
    },
    confirm: function () {
      if (this.cartId === '') {
        this.tempMessage = 'Please identify a cart.';
        this.variant = "warning";
        this.dismissCountDown = 5;
        return;
      }

      let createClusterRequest = {};
      createClusterRequest.userId = 'openwes';
      createClusterRequest.clusterId = this.cartId;
      const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/api/picking/Cluster';
      this.$http.post(appsUrl, createClusterRequest)
          .then((response) => {
            location.href = '#/picking/cart/' + this.cartId + '/addContainer';
          })
          .catch(function (error) {
            console.error('An error occurred while building the cluster: ', error);
          });
    },
    cancel: function() {
      location.href = '#/picking/induct/';
    }
  },
}
</script>