import Vue from 'vue'

export function setConfigurations() {
    Vue.prototype.$userId = '';
    Vue.prototype.$zoneId = '';
    Vue.prototype.$message = '';
    Vue.prototype.$tempMessage = '';
    Vue.prototype.$dismissCountDown = 0;
    Vue.prototype.$host = getHostUrl();
    Vue.prototype.$ordersPort = getPort();
    Vue.prototype.$inventoryPort = getPort();
    Vue.prototype.$pickingPort = getPort();
    Vue.prototype.$receivingPort = getPort();
    Vue.prototype.$putawayPort = getPort();
    Vue.prototype.$orderConsolidationPort = getPort();
    Vue.prototype.$simulationPort = '9090'
    Vue.prototype.$webUiServicePort = getPort();
    Vue.prototype.$configurationServicePort = getPort();
    Vue.prototype.$userManagementPort = getPort();
    Vue.prototype.$qcPort = getPort();
    Vue.prototype.$docsPort = getPort();
    Vue.prototype.$isLoggedIn = false;
    Vue.prototype.$serverUri = '192.168.1.40';
    Vue.prototype.$simulationUri = 'localhost';
}

export function getHostUrl() {
    let domain = getUrl();
    let hostname = domain.hostname;
    return hostname;
}

export function getPort() {
    let url = getUrl();
    let port = url.port;
    if (port == '8080' || port == '8081') {
        return '8100'
    }

    return port;
}

export function getSimulationHostUrl() {
    return 'http://localhost';
}

export function setUserId(userId) {
    localStorage.setItem('userId', userId)
}

export function getUserId() {
    return localStorage.getItem('userId');
}

export function setZoneId(zoneId) {
    localStorage.setItem('zoneId', zoneId);
}

export function getZoneId() {
    return localStorage.getItem('zoneId');
}

function getUrl() {
    let url = window.location.href;
    let domain = (new URL(url));
    return domain;
}

export function formatDate(date) {
    let newDate = new Date(date);
    let localeStringDate = newDate.toLocaleString();
    return newDate.toLocaleString();
}