<template>
  <div>
    <v-alert
        v-show="isAlertVisible"
        :type="variant"
        dismissible>
      <p>{{ tempMessage }}</p>
    </v-alert>
    <div>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        Scan or enter cart to induct
      </v-toolbar>
      <v-text-field
          v-model="clusterId"
          placeholder="Cart Barcode"
          v-on:keyup.enter="confirm()"
          autofocus
      />
      <v-btn-toggle>
        <v-btn
            v-on:click="confirm()"
            :dark="!isConfirmDisabled()"
            :disabled="isConfirmDisabled()"
        >
          Confirm
        </v-btn>
        <v-btn
            v-on:click="buildCart()"
        >
          Build Cart
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>
<script>
import * as fulfillment from "../../../js/fulfillment";
export default {
  name: 'InductCartOrContainer',
  props: {
    msg: String
  },
  data() {
    return {
      userId: 'user1',
      zoneId: 'PICK01',
      currentState: '',
      clusterId: '',
      dismissCountDown: 0,
      dismissSeconds: 5,
      variant: "success",
      tempMessage: "",
    }
  },
  methods: {
    countDownChanged: function (dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    isConfirmDisabled: function () {
      return this.clusterId === '';
    },
    isAlertVisible: function() {
      return false;
    },
    buildCart: function() {
      location.href = '#/picking/cart/build'
    },
    confirm: function () {
      if (this.clusterId === '') {
        this.tempMessage = 'Please identify a cart.';
        this.variant = "warning";
        this.dismissCountDown = 5;
        return;
      }

      fulfillment.setClusterId(this.clusterId);
      let createClusterRequest = {};
      createClusterRequest.userId = 'openwes';
      createClusterRequest.clusterId = this.clusterId;
      const worklist = fulfillment.getWorklistForCluster(this.userId, this.zoneId, this.clusterId);
      if (!worklist.isSuccess) {
        this.tempMessage = 'Cart does not exist';
        this.variant = 'warning';
        this.dismissCountDown = 5;
        return;
      }
      console.log('WorkList: ', worklist);
      location.href = '#/picking/pick';
    },
  },
}
</script>