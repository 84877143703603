<template>
  <div>
    <v-alert
        v-model="isAlertVisible"
        :type="variant"
        dismissible>
      <p>{{ tempMessage }}</p>
    </v-alert>
    <div>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        Login to zone
      </v-toolbar>
      <v-text-field
          v-model="locationOrZoneId"
          placeholder="Enter location or zone"
          v-on:keyup.enter="confirm()"
          autofocus
      />
      <v-btn-toggle>
        <v-btn
            v-on:click="confirm()"
            :dark="!isConfirmDisabled()"
            :disabled="isConfirmDisabled()"
        >
          Confirm
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>
<script>
import * as configurations from '../../js/configurations';
export default {
  name: 'LoginZone',
  props: {
    msg: String
  },
  data() {
    return {
      userId: 'test1',
      zoneId: '',
      locationOrZoneId: '',
      currentState: '',
      delivery: '',
      dismissCountDown: 0,
      dismissSeconds: 10,
      variant: "success",
      tempMessage: "Test",
      isAlertVisible: false,
    }
  },
  methods: {
    countDownChanged: function(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    isConfirmDisabled: function() {
      if (this.locationOrZoneId === '') {
        return true;
      }

      return false;
    },
    showAlert: function(errorMessage) {
      this.tempMessage = errorMessage;
      this.variant = "warning";
      this.isAlertVisible = true;
    },
    confirm: async function() {
      if (this.locationOrZoneId === '') {
        this.showAlert('Please identify a location or zone to continue.');
        return;
      }

      const appsUrl = 'http://' + this.$host + ':' + this.$configurationServicePort + '/api/warehouse-configuration/Topology/Zone/' + this.locationOrZoneId + '/1/'
      try {
        const response = await this.$http.post(appsUrl);
        console.log('Response: ', response.data);
        let zoneResponse = response.data;
        configurations.setZoneId(this.locationOrZoneId);
        if (zoneResponse.workflowId === 'PUT') {
          location.href = '#/orderConsolidation/inductSku';
        } else if (zoneResponse.workflowId === 'PACK') {
          location.href = '#/orderConsolidation/packing/packOrder';
        } else if (zoneResponse.workflowId === 'PICK') {
          location.href = '#/picking/induct';
        }
      } catch (exception) {
        if (exception.response) {
          this.showAlert(exception.response.data);
        } else if (exception.request) {
          this.showAlert('An error occurred');
          console.error('An error occurred while inducting the delivery container: ', exception.request);
        } else {
          this.showAlert('An error occurred');
          console.error('An error occurred while inducting the delivery container: ', exception.message);
        }
      }
    },
  },
}
</script>