<!--<template>-->
<!--  <div class="pick">-->
<!--    <h4>Go to {{ locationId }}</h4>-->
<!--    <h4>Pick {{ quantity }}</h4>-->
<!--    <input v-model="quantity"/>-->
<!--    <button v-on:click="ready()" class="btn btn-primary">Confirm</button>-->
<!--    <div class="options">-->
<!--      <button v-on:click="logout()" class="btn btn-primary">Logout</button>-->
<!--    </div>-->
<!--  </div>-->

<template>
  <div>
    <v-alert
        v-model="isAlertVisible"
        :type="variant"
        dismissible>
      <p>{{ tempMessage }}</p>
    </v-alert>
    <div>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        Pick {{ pick.qtyRequested }} at {{ pick.locationId }}
      </v-toolbar>
      <v-text-field
          v-model="quantity"
          placeholder="Enter location or zone"
          v-on:keyup.enter="confirm()"
          autofocus
      />
      <v-btn-toggle>
        <v-btn
            v-on:click="confirm()"
            :dark="!isConfirmDisabled()"
            :disabled="isConfirmDisabled()"
        >
          Confirm
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
import * as fulfillment from "../../../js/fulfillment";
import * as configurations from "../../../js/configurations";
import axios from "axios";
export default {
  name: 'Pick',
  props: {
    msg: String
  },
  data() {
    return {
      locationId: '',
      quantity: 0,
      containerId: '',
      pick: {},
      isAlertVisible: false,
      tempMessage: '',
      variant: 'warning',
    }
  },
  methods: {
    confirm: async function () {
      console.log('Confirming a pick');
      const userId = configurations.getUserId();
      const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/api/picking/Pick?pickId=' + this.pick.pickId + '&pickContainerId=' + this.containerId + '&qtyPicked=' + this.pick.qtyRequested + '&pickType=COMPLETE&userId=' + userId
      console.log('Confirm pick url: ', appsUrl);
      await axios.post(appsUrl)
      await this.getWorkList();
    },
    isConfirmDisabled: function () {
      return false;
    },
    logout: function () {
      const userId = localStorage.getItem("userId");
      const zoneId = localStorage.getItem("zoneId");
      const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/Login/?userName=' + userId + '&zoneId=' + zoneId
      this.$http.delete(appsUrl)
          .then((response) => {
            if (response.data.isSuccess !== true) {
              this.tempMessage = response.data.message;
            }
            location.href = '';
          })
    },
    getWorkList: async function() {
      const userId = configurations.getUserId();
      const zoneId = configurations.getZoneId();
      const cartId = fulfillment.getClusterId();
      const response = await fulfillment.getWorklistForCluster(userId, zoneId, cartId);
      if (response.worklist[0].pickList.length() < 1) {
        this.tempMessage = 'Cart does not exist';
        this.variant = 'warning';
        this.dismissCountDown = 5;
        location.href = '#/picking/induct';
      }

      this.pick = response.workList[0].pickList[0];
      this.containerId = response.workList[0].pickContainerId;
    }
  },
  mounted: async function () {
    this.getWorkList();
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>